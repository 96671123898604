import React from 'react';
import { useRouter } from 'next/router';
import sanitizer from '@/utils/sanitizer';
import Plants from '@/assets/svgs/plant.svg';
import Topo from '@/assets/svgs/topo.svg';
import Button from '@/components/Button/Button';
import routes from '@/data/routes';

type Props = {
  fields: {
    label: string;
    labelEn: string;
    title: string;
    titleEn: string;
    text: string;
    textEn: string;
  };
};

function Territorio({ fields }: Props) {
  const { label, labelEn, title, titleEn, text, textEn } = fields;
  const router = useRouter();

  return (
    <section className="territorio" data-scroll-section>
      <div
        className="section-sc"
        data-scroll
        data-scroll-call="territorio"
        data-scroll-repeat
        data-scroll-offset="90%"
      />
      <div className="territorio__bgTopo" data-scroll data-scroll-speed="-0.7" data-scroll-direction="vertical">
        <Topo />
      </div>
      <div className="territorio__bgPlant">
        <div
          className="territorio__bgPlant-inner"
          data-scroll
          data-inview-opacity
          data-scroll-speed="-0.7"
          data-scroll-direction="horizontal"
        >
          <Plants />
        </div>
      </div>
      <div className="territorio__content">
        <div className="text-component text-component--light text-component--center" data-scroll data-text-reveal>
          <div className="text-component__label">
            <span>{router.locale === 'en' ? labelEn : label}</span>
          </div>
          <div className="territorio__content-title text-component__title text-reveal">
            {router.locale === 'en' ? titleEn : title}
          </div>
          <div
            className="territorio__content-text text-component__teaser text-reveal"
            dangerouslySetInnerHTML={{ __html: sanitizer(router.locale === 'en' ? textEn : text) }}
          />
          <Button link={routes.About.path} text={router.locale === 'en' ? 'Explore' : 'Esplora'} stroke="light" />
        </div>
      </div>

      <style jsx>{`
        @import '../../styles/shared';
        .territorio {
          width: 100%;
          position: relative;
          overflow: hidden;
          @include flex-center;
          background-color: $dark;
          padding: 14vmax 8vmax 24vmax;
          @include media('>=large') {
            padding: 8vmax 10vmax 8vmax 10vmax;
            height: 100vh;
            width: auto;
            min-width: 100vw;
          }
        }
        .section-sc {
          @include fill;
          position: absolute;
          pointer-events: none;
        }
        .territorio__bgTopo {
          @include pos100(absolute);
          &:after {
            content: '';
            @include pos100(absolute);
            height: 120%;
            background: linear-gradient(to right, rgba($dark, 1), rgba($dark, 0), rgba($dark, 1));
          }
          :global(svg) {
            opacity: 0;
            width: 200%;
            left: 100%;
            height: auto;
            transform: scale(1.2);
            transform-origin: center;
            will-change: transform;
            transition: opacity 0.3s ease, transform 1.6s ease;
            @include media('>=medium') {
              left: auto;
              width: 100%;
            }
          }
          :global(svg path) {
            fill: none;
            stroke: $white;
          }
          &.is-inview {
            :global(svg) {
              opacity: 0.07;
              transform: scale(1);
            }
          }
        }
        .territorio__bgPlant {
          position: absolute;
          bottom: px(-35);
          opacity: 0.5;
          @include center(x);
          width: px(160);
          @include media('>=msmall') {
            width: px(180);
          }
          @include media('>=medium') {
            bottom: px(-45);
            width: px(230);
          }
          @include media('>=large') {
            bottom: px(-85);
            width: px(280);
            width: clamp(px(180), 30%, px(340));
          }
          @include media('>=fullhd') {
            bottom: px(-75);
            width: clamp(px(180), 34%, px(350));
          }
          :global(svg) {
            width: 100%;
            height: auto;
            transform-origin: center;
            transform: scaleX(-1);
          }
        }
        .territorio__content {
          height: 100%;
          @include flex-center;
          flex-direction: column;
          padding-bottom: 5vw;
        }
        .text-component {
          width: 70vw;
          @include media('>=medium') {
            width: 80vw;
          }
          @include media('>=large') {
            width: 60vw;
          }
        }
        .text-component__title {
          font-size: 1.8rem;
          line-height: 1;
          font-size: clamp(1.8rem, 0.8783rem + 4.0964vw, 3.5rem);
          @include media('>=large') {
            font-size: 4rem;
            font-size: clamp(3rem, 1.2585rem + 2.7211vw, 5rem);
          }
        }
      `}</style>
    </section>
  );
}

export default Territorio;
