import React, { useEffect, useRef } from 'react';
import Image from '@/components/Image/WpImage';
import { gsap } from 'gsap';
import Circle from '@/assets/svgs/circle.svg';
import Arrow from '@/assets/svgs/arrow-right-intro-2.svg';
import { setCursorState, useAppDispatch, useAppSelector } from '@/redux';
import { device } from '@jam3/detect';
import Hero3d from './Hero3d/Hero3d';

type Props = {
  imgBg: string;
  imgBgMobile: string;
};

function Hero({ imgBg, imgBgMobile }: Props) {
  const ageGate = useAppSelector((state) => state.ageGate);
  const TimeLineIn = useRef<GSAPTimeline | null>(null);
  const HeroGl = useRef<HTMLDivElement | null>(null);
  const HeroGlRef = useRef<Object | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (device.desktop && !HeroGlRef.current) {
      HeroGlRef.current = new Hero3d(HeroGl.current, imgBg);
    }

    if (!ageGate) {
      TimeLineIn.current = gsap
        .timeline({ defaults: { ease: 'expo' } })
        .set('.hero-textLine-inner', {
          y: '150%',
          rotate: 15
        })
        .set('.hero__cta', {
          y: '10%',
          opacity: 0
        })
        .to('.hero-textLine-inner', {
          y: '0%',
          rotate: 0,
          stagger: 0.03,
          duration: 2
        })
        .to(
          '.hero__bg',
          {
            scale: 1,
            duration: 3
          },
          0.1
        )
        .to(
          '.hero__cta',
          {
            y: '0%',
            opacity: 1,
            duration: 2.4
          },
          0.8
        );
    }
  }, [ageGate]);

  return (
    <section className="hero" data-scroll-section>
      <div className="hero__bg">
        {device.desktop ? (
          <div className="hero__bg-wrap">
            <img className="hero__bg-cover" src={imgBg} alt="i.o.l.e." />
            <div className="hero__bg-gl" ref={HeroGl}></div>
          </div>
        ) : (
          <Image className="hero__bg-mobile" loadingType="eager" sourceUrl={imgBgMobile} alt="i.o.l.e." />
        )}
      </div>
      <div className="hero__content">
        <div className="hero__content-title" data-scroll data-scroll-speed="-1" data-scroll-direction="horizontal">
          <div>
            <p className="hero-textLine-inner">INFUSO</p>
          </div>
          <div>
            <p className="hero-textLine-inner">ORGANICO</p>
          </div>
          <div>
            <p className="hero-textLine-inner">LOCALE</p>
          </div>
          <div>
            <p className="hero-textLine-inner">ESSENZIALE</p>
          </div>
        </div>
        <div className="hero__cta">
          <div className="hero__cta-wrappper" data-scroll data-scroll-speed="-0.5" data-scroll-direction="horizontal">
            <div
              className="hero__cta-icon"
              onMouseEnter={() => {
                device.desktop && dispatch(setCursorState('hover'));
              }}
              onMouseLeave={() => {
                device.desktop && dispatch(setCursorState('default'));
              }}
            >
              <span className="hero__cta-icon-circ">
                <Circle />
              </span>
              <span className="hero__cta-icon-arrow">
                <Arrow />
              </span>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @import '../../styles/shared';

        .hero {
          width: 100%;
          position: relative;
          height: 100vh;
          height: -webkit-fill-available;
          @include flex-center;
          overflow: hidden;
          @include media('>=large') {
            height: 100vh;
            width: 100vw;
          }
        }
        .hero__bg {
          transform: scale(1.2);
          @include pos100(absolute);
          &:after {
            content: '';
            z-index: 2;
            @include pos100(absolute);
            background: linear-gradient(to right, rgba($dark, 0.1) 25%, rgba($dark, 0) 70%);
            @include media('>=large') {
              background: linear-gradient(to right, rgba($dark, 0.2) 15%, rgba($dark, 0) 45%);
            }
          }
        }
        .hero__bg-wrap {
          @include pos100(absolute);
        }
        .hero__bg-gl {
          position: absolute;
          width: 100%;
          height: 120%;
          bottom: 0;
          left: 0;
          :global(canvas) {
            @include pos100(absolute);
          }
        }
        .hero__bg-cover {
          position: absolute;
          width: 100%;
          height: 120%;
          bottom: 0;
          left: 0;
          object-fit: cover;
        }
        :global(.hero__bg-mobile) {
          @include pos100(absolute);
          width: 100%;
          width: 100%;
          object-fit: cover;
        }
        .hero__content {
          position: relative;
          width: 90vw;
          display: flex;
          z-index: 2;
          @include media('>=large') {
            width: 80vw;
            padding-left: 5vw;
          }
        }
        .hero__content-title {
          display: grid;
          flex-grow: 1;
          grid-template-columns: auto auto auto;
          text-align: left;
          align-content: center;
          justify-content: flex-start;
          > div {
            grid-column: 2;
            line-height: 1;
            overflow: hidden;
          }
          p {
            @include clean;
            color: $white;
            font-weight: 700;
            line-height: 1.1;
            letter-spacing: px(1);
            transform-origin: 0% 50%;
            will-change: transform;
            transform: translateY(150%);
            font-size: 2.8rem;
            font-size: clamp(2.7rem, 0.9108rem + 7.9518vw, 6rem);
            @include media('>=large') {
              letter-spacing: px(2);
              font-size: 5rem;
              font-size: clamp(4.5rem, 5.4vw + 1rem, 8.5rem);
            }

            &::first-letter {
              color: $red;
            }
          }
        }
        .hero__cta {
          display: none;
          @include media('>=large') {
            @include flex-center;
            transform: translateY(4%);
            opacity: 0;
          }
        }
        .hero__cta-icon {
          position: relative;
          @include media('>=large') {
            transform: scale(1.1);
          }
          @include media('>=slarge') {
            transform: scale(1.2);
          }
          @include media('>=mlarge') {
            transform: scale(1.3);
          }
          @include media('>=hd') {
            transform: scale(1.6);
          }
          &:hover {
            cursor: pointer;
            .hero__cta-icon-circ {
              :global(svg path) {
                fill: $dark;
                stroke: transparent;
              }
            }
            .hero__cta-icon-arrow {
              transform: translate(-20%, -50%);
            }
          }
        }
        .hero__cta-icon-circ {
          @include fill;
          :global(svg) {
            width: 3.8em;
            height: auto;
            fill: transparent;
            stroke: $white;
            stroke-dasharray: 170;
            stroke-dashoffset: 0;
            stroke-width: 2px;
            transform: rotate(-60deg);
            transform-origin: center;
            transition: all 0.3s ease;
          }
        }
        .hero__cta-icon-arrow {
          position: absolute;
          @include center(xy);
          left: 30%;
          width: px(28);
          will-change: transform;
          transition: transform 0.6s $ease-out-quart;
          :global(svg) {
            width: 100%;
            height: auto;
          }
          :global(svg path) {
            fill: $white;
          }
        }
      `}</style>
    </section>
  );
}

export default Hero;
