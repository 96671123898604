import React from 'react';
import Image from '@/components/Image/WpImage';
import { useRouter } from 'next/router';
import sanitizer from '@/utils/sanitizer';
import Logo from '@/assets/svgs/logo.svg';
import Plants from '@/assets/svgs/plant.svg';

type Props = {
  fields: {
    image1: { sourceUrl: string; srcSet: string };
    image2: { sourceUrl: string; srcSet: string };
    label: string;
    labelEn: string;
    title: string;
    titleEn: string;
    text: string;
    textEn: string;
  };
};

function Tonico({ fields }: Props) {
  const { image1, image2, label, labelEn, title, titleEn, text, textEn } = fields;
  const router = useRouter();

  return (
    <section className="tonico" data-scroll-section>
      <div className="section-sc" data-scroll data-scroll-call="tonico" data-scroll-repeat data-scroll-offset="95%" />
      <div
        className="tonico__bgText"
        data-scroll
        data-inview-opacity
        data-scroll-speed="-1.3"
        data-scroll-direction="horizontal"
      >
        <Logo />
      </div>
      <div className="tonico__bgImage" data-scroll data-image-reveal data-inview-delay="0.2">
        <Image sourceUrl={image2?.sourceUrl} sourceSrcSet={image2?.srcSet} alt="i.o.l.e." />
      </div>

      <div
        className="tonico__bgPlant"
        data-scroll
        data-inview-opacity
        data-scroll-speed="-0.7"
        data-scroll-direction="horizontal"
      >
        <Plants />
      </div>

      <div className="tonico__content">
        <div className="text-component text-component--light" data-scroll data-text-reveal>
          <div className="text-component__label">
            <span>{router.locale === 'en' ? labelEn : label}</span>
          </div>
          <div className="tonico__content-title text-component__title text-reveal">
            {router.locale === 'en' ? titleEn : title}
          </div>
          <div
            className="tonico__content-text text-component__teaser text-reveal"
            dangerouslySetInnerHTML={{ __html: sanitizer(router.locale === 'en' ? textEn : text) }}
          />
        </div>
        <div
          className="tonico__content-img"
          data-scroll
          data-scroll-speed="1"
          data-scroll-direction="horizontal"
          data-image-zoom-reveal
        >
          <figure>
            <Image sourceUrl={image2?.sourceUrl} sourceSrcSet={image1?.srcSet} alt="i.o.l.e." />
          </figure>
        </div>
      </div>

      <style jsx>{`
        @import '../../styles/shared';
        .tonico {
          width: 100%;
          position: relative;
          overflow: hidden;
          @include flex-center;
          background-color: $dark;
          padding: 14vmax 0 24vmax;
          flex-direction: column;
          @include media('>=medium') {
            padding: 17vmax 0 26vmax;
          }
          @include media('>=large') {
            padding: 8vmax 45vmax 8vmax 25vmax;
            height: 100vh;
            width: auto;
            min-width: 100vw;
          }
        }
        .section-sc {
          @include fill;
          position: absolute;
          pointer-events: none;
        }
        .tonico__bgPlant {
          position: absolute;
          bottom: 0;
          left: -10%;
          width: 65%;
          @include media('>=medium') {
            width: 55%;
          }
          @include media('>=large') {
            left: 0;
            width: px(170);
            width: clamp(px(120), 35%, px(340));
          }
          :global(svg) {
            width: 100%;
            height: auto;
            position: relative;
            transform: scaleX(-1) rotate(320deg);
            top: px(30);
            left: 8%;
            opacity: 0.35;
            @include media('>=medium') {
              top: px(40);
              left: 4%;
            }
            @include media('>=large') {
              top: px(50);
              left: -35%;
            }
            @include media('>=slarge') {
              left: -25%;
            }
            @include media('>=mlarge') {
              left: -20%;
            }
            :global(path) {
              fill: $white;
            }
          }
        }
        .tonico__bgText {
          position: absolute;
          top: px(-40);
          left: 13vw;
          width: 150vw;
          @include media('>=medium') {
            width: 130vw;
          }
          @include media('>=large') {
            top: 1vmax;
            left: 13vmax;
            width: 80vmax;
          }

          :global(svg) {
            width: 100%;
            height: auto;
            opacity: 0.02;
            @include media('>=large') {
              opacity: 0.04;
            }

            :global(path) {
              fill: $white;
            }
          }
        }
        .tonico__bgImage {
          display: block;
          position: absolute;
          right: 0;
          bottom: 14vmax;
          width: 30vmax;
          height: 40vmax;
          @include media('>=medium') {
            width: 40vmax;
            height: 35vmax;
            bottom: 20vmax;
          }
          @include media('>=large') {
            right: 0;
            top: 0;
            bottom: 0;
            width: 40%;
            height: 100%;
          }
        }
        .tonico__content {
          height: 100%;
          z-index: 2;
          @include flex-center;
          flex-direction: column;
          @include media('>=large') {
            flex-direction: row;
          }
        }
        .text-component {
          width: 70vw;
          padding-bottom: var(--space-xl);
          @include media('>=medium') {
            width: 60vw;
          }
          @include media('>=large') {
            width: 50vw;
            padding-right: 5vw;
            padding-bottom: 0;
          }
        }
        .tonico__content-img {
          width: 25vmax;
          height: 40vmax;
          right: 5vmax;
          @include media('>=medium') {
            right: 7vmax;
          }

          @include media('>=large') {
            right: 0;
            width: clamp(pxrem(400), 30vw, pxrem(500));
            height: clamp(pxrem(300), 100%, pxrem(700));
          }
        }
      `}</style>
    </section>
  );
}

export default Tonico;
