import { memo, useRef, useEffect } from 'react';
import Circle from '@/assets/svgs/circle.svg';
import Arrow from '@/assets/svgs/arrow-right.svg';
import { setCursorLocked, useAppDispatch } from '@/redux';
import { device } from '@jam3/detect';
import Link from 'next/link';

function Button({ link, text, stroke }: { link: string; text: string; stroke: string }) {
  const dispatch = useAppDispatch();
  const circle = useRef<HTMLElement | null>(null);
  const DOMref = useRef<HTMLCollectionOf<HTMLHtmlElement> | null>(null);

  useEffect(() => {
    DOMref.current = document.getElementsByTagName('html');
  }, []);

  const handleCursorLocked = () => {
    if (DOMref.current?.length && !DOMref.current[0].classList.contains('has-scroll-scrolling')) {
      const bounds = circle.current?.getBoundingClientRect();
      if (bounds) {
        device.desktop &&
          dispatch(setCursorLocked({ locked: true, x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 }));
        device.desktop && DOMref.current[0].classList.add('button-locked');
      }
    }
  };

  return (
    <div
      className="Button"
      onMouseEnter={handleCursorLocked}
      onMouseLeave={() => {
        device.desktop && dispatch(setCursorLocked({ locked: false, x: 0, y: 0 }));
        device.desktop && DOMref.current?.length && DOMref.current[0].classList.remove('button-locked');
      }}
    >
      <Link href={link} prefetch={false}>
        <a>
          <span className="Button__circ" ref={circle}>
            <Circle />
          </span>
          <span className="Button__arrows">
            <Arrow />
            <Arrow />
          </span>
          <span className="Button__text">{text}</span>
        </a>
      </Link>
      <style jsx>{`
        @import '../../styles/shared';

        .Button {
          @include flex-center;
          position: relative;
          margin-top: px(20);
          width: px(40);
          height: px(40);
          text-align: left;
          // :global(> a) {
          //   @include clean;
          //   @include fill;
          // }
          a {
            display: inline-flex;
            @include clean;
            @include flex-center;
          }
          .Button__circ {
            @include fill;
            @include flex-center;
            transform: scale(1);
            opacity: 1;
            transition: transform 0.3s ease, opacity 0.3s ease;
            will-change: transform;
            :global(svg) {
              width: 100%;
              fill: transparent;
              stroke: ${stroke === 'dark' ? '#221f20' : '#fff'};
              stroke-dasharray: 170;
              stroke-dashoffset: 0;
              stroke-width: 2px;
              transform: rotate(125deg);
              transform-origin: center;
            }
          }
          .Button__arrows {
            :global(svg) {
              position: absolute;
              bottom: auto;
              right: auto;
              top: 50%;
              left: 50%;
              width: px(20);
              height: auto;
            }
            :global(svg:nth-child(1)) {
              opacity: 0;
              transition: transform 0.2s ease, opacity 0.2s ease;
              transform: translate(-80%, -50%);
              will-change: transform;
              fill: $red;
            }
            :global(svg:nth-child(2)) {
              transition: transform 0.3s ease 0.2s, opacity 0.3s ease 0.2s;
              transform: translate(-50%, -50%);
              will-change: transform;
              fill: ${stroke === 'dark' ? '#221f20' : '#fff'};
            }
          }
          .Button__text {
            position: absolute;
            text-transform: uppercase;
            font-size: var(--text-sm);
            letter-spacing: 2px;
            color: $red;
            font-weight: 400;
            bottom: auto;
            top: 50%;
            transition: transform 0.6s ease 0.1s;
            transform: translate(80%, -50%);
            will-change: transform;
          }
          @keyframes animate-btn-circ {
            0% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0;
              transform: scale(1.2);
            }
            51% {
              opacity: 0;
              transform: scale(0.8);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }

          &:hover {
            .Button__text {
              transform: translate(120%, -50%);
            }
            .Button__arrows {
              :global(svg:nth-child(1)) {
                opacity: 1;
                transform: translate(-50%, -50%);
                transition: transform 0.3s ease 0.2s, opacity 0.3s ease 0.2s;
              }
              :global(svg:nth-child(2)) {
                opacity: 0;
                transform: translate(-20%, -50%);
                transition: transform 0.2s ease, opacity 0.2s ease;
              }
            }
          }
        }
        :global(.button-locked .Button:hover) {
          .Button__circ {
            opacity: 0;
            transform: scale(1.2);
          }
        }
        :global(html:not(.button-locked) .Button:hover) {
          .Button__circ {
            animation: animate-btn-circ 0.6s forwards;
            :global(svg) {
              stroke-dasharray: 360;
              stroke: $red;
            }
          }
        }
      `}</style>
    </div>
  );
}

export default memo(Button);
