import { memo, useEffect } from 'react';
import { m } from 'framer-motion';
import Head from '@/components/Head/Head';
import Hero from '@/components/Home/Hero';
import Tonico from '@/components/Home/Tonico';
import Ricordo from '@/components/Home/Ricordo';
import Sentori from '@/components/Home/Sentori';
import Territorio from '@/components/Home/Territorio';
import Mixology from '@/components/Home/Mixology';
import NextPage from '@/components/Home/NextPage';
import TextReveal from '@/utils/split-text';
import { useRouter } from 'next/router';
import { device } from '@jam3/detect';
// import { useAppSelector } from '@/redux';

import fetchAPI from '../api/graphql-simple-fetch';
import { LANDING_QUERY } from '../api/sharedQueries';

const heroImg = require('@/assets/images/hero.jpg');
// const heroImgWebp = require('@/assets/images/hero.jpg?webp');

function Home({ page }: any) {
  const { featuredImage, landing_fields, seo } = page;
  // const isWebpSupported = useAppSelector((state) => state.isWebpSupported);
  const metaTitle = seo && seo.title;
  const metaDesc = seo && seo.metaDesc;
  const router = useRouter();
  // const imgBg = isWebpSupported ? heroImgWebp : heroImg;
  const imgBg = heroImg;
  const imgBgMobile = featuredImage?.node.sourceUrl;
  const updateText = () => {
    TextReveal('.text-reveal', false);
  };

  useEffect(() => {
    TextReveal('.text-reveal', true);
    window.addEventListener('resize', updateText, false);
    return () => {
      window.removeEventListener('resize', updateText);
    };
  }, [router.locale]);

  return (
    <m.div
      className="Pages Pages--Home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
    >
      <Head title={metaTitle} description={metaDesc}>
        <link rel="preload" as="image" href={`${device.desktop ? imgBg : imgBgMobile}`}></link>
      </Head>

      <Hero imgBg={imgBg} imgBgMobile={imgBgMobile} />
      <Tonico fields={landing_fields.introTonico} />
      <Ricordo fields={landing_fields.introRicordo} />
      <Sentori fields={landing_fields.introSentori} />
      <Territorio fields={landing_fields.introTerritorio} />
      <Mixology fields={landing_fields.introMixology} />
      <NextPage />
    </m.div>
  );
}

export async function getStaticProps() {
  const landingQuery = await fetchAPI(LANDING_QUERY);

  return {
    props: {
      page: landingQuery.data.page
    },
    revalidate: 300
  };
}

export default memo(Home);
