import React from 'react';
import { useRouter } from 'next/router';
import { setCursorState, useAppDispatch } from '@/redux';
import routes from '@/data/routes';
import { device } from '@jam3/detect';
import Link from 'next/link';

function NextPage() {
  const dispatch = useAppDispatch();
  const router = useRouter();

  return (
    <section className="nextPage" data-scroll-section>
      <div className="nextPage-wrapper" data-scroll data-inview-opacity>
        <Link href={routes.Tonico.path} prefetch={false}>
          <a
            className="nextPage__link"
            onMouseEnter={() => {
              device.desktop && dispatch(setCursorState('hover'));
            }}
            onMouseLeave={() => {
              device.desktop && dispatch(setCursorState('default'));
            }}
          >
            <div className="nextPage__link-wrap">
              <div className="nextPage__link-label">{router.locale === 'en' ? 'NEXT' : 'SUCCESSIVO'}</div>
              <div className="nextPage__link-text">
                {router.locale === 'en' ? routes.Tonico.title_en : routes.Tonico.title_it}
              </div>
            </div>
          </a>
        </Link>
      </div>

      <style jsx>{`
        @import '../../styles/shared';
        .nextPage {
          position: relative;
          overflow: hidden;
          background-color: $dark;
          display: flex;
          padding: 20vmax 0;
          transition: background-color 0.35s ease;
          @include media('>=large') {
            height: 100vh;
            min-width: 30vw;
          }
          &:hover {
            cursor: pointer;
            background-color: darken($dark, 2%);
          }
        }
        .nextPage-wrapper {
          @include pos100(absolute);
        }
        .nextPage__link {
          @include pos100(absolute);
          @include flex-center;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
        .nextPage__link-wrap {
          text-align: center;
          @include media('>=large') {
            transform: rotate(-90deg);
          }
        }
        .nextPage__link-label {
          display: block;
          color: $red;
          font-family: var(--font-tertiary);
          text-transform: uppercase;
          line-height: 1.2;
          margin-bottom: var(--space-xs);
          letter-spacing: 0.3rem;
          font-size: 1rem;
          font-size: clamp(1rem, 0.8373rem + 0.7229vw, 1.3rem);
          @include media('>=large') {
            letter-spacing: 0.5rem;
            font-size: 1.2rem;
            font-size: clamp(1rem, 0.3034rem + 1.0884vw, 1.8rem);
          }
        }
        .nextPage__link-text {
          display: block;
          font-family: var(--font-secondary);
          text-transform: uppercase;
          color: $white;
          letter-spacing: 0.25rem;
          line-height: 1;
          font-weight: bold;
          font-weight: 650;
          font-size: 3rem;
          font-size: clamp(2rem, 0.6446rem + 6.0241vw, 4.5rem);
          @include media('>=large') {
            letter-spacing: 0.5rem;
            font-size: 4rem;
            font-size: clamp(3rem, 1.2585rem + 2.7211vw, 5rem);
          }
        }
      `}</style>
    </section>
  );
}

export default NextPage;
