import React from 'react';
import Image from '@/components/Image/WpImage';
import { useRouter } from 'next/router';
import sanitizer from '@/utils/sanitizer';
import Button from '@/components/Button/Button';
import routes from '@/data/routes';

type Props = {
  fields: {
    gallery: Array<{ srcSet: string; sourceUrl: string }>;
    label: string;
    labelEn: string;
    title: string;
    titleEn: string;
    text: string;
    textEn: string;
  };
};

function Mixology({ fields }: Props) {
  const { gallery, label, labelEn, title, titleEn, text, textEn } = fields;
  const router = useRouter();

  return (
    <section className="mixology" data-scroll-section>
      <div className="section-sc" data-scroll data-scroll-repeat data-scroll-offset="99%" />
      <div className="mixology__tiles" data-scroll data-inview-opacity id="mixology-grid">
        <div className="mixology__tiles-wrap">
          <div
            className="mixology__tiles-line"
            data-scroll
            data-scroll-speed="-1"
            data-scroll-direction="vertical"
            data-scroll-target="#mixology-grid"
          >
            <figure className="mixology__tiles-line-img">
              <Image sourceUrl={gallery[0]?.sourceUrl} sourceSrcSet={gallery[0]?.srcSet} alt="i.o.l.e." />
            </figure>
            <figure className="mixology__tiles-line-img">
              <Image sourceUrl={gallery[1]?.sourceUrl} sourceSrcSet={gallery[1]?.srcSet} alt="i.o.l.e." />
            </figure>
          </div>
          <div
            className="mixology__tiles-line"
            data-scroll
            data-scroll-speed="1"
            data-scroll-direction="vertical"
            data-scroll-target="#mixology-grid"
          >
            <figure className="mixology__tiles-line-img">
              <Image sourceUrl={gallery[2]?.sourceUrl} sourceSrcSet={gallery[2]?.srcSet} alt="i.o.l.e." />
            </figure>
            <figure className="mixology__tiles-line-img">
              <Image sourceUrl={gallery[3]?.sourceUrl} sourceSrcSet={gallery[3]?.srcSet} alt="i.o.l.e." />
            </figure>
          </div>
          <div
            className="mixology__tiles-line"
            data-scroll
            data-scroll-speed="-1"
            data-scroll-direction="vertical"
            data-scroll-target="#mixology-grid"
          >
            <figure className="mixology__tiles-line-img">
              <Image sourceUrl={gallery[4]?.sourceUrl} sourceSrcSet={gallery[4]?.srcSet} alt="i.o.l.e." />
            </figure>
            <figure className="mixology__tiles-line-img">
              <Image sourceUrl={gallery[5]?.sourceUrl} sourceSrcSet={gallery[5]?.srcSet} alt="i.o.l.e." />
            </figure>
          </div>
        </div>
      </div>
      <div className="mixology__content">
        <div className="text-component text-component--center" data-scroll data-text-reveal>
          <div className="text-component__label">
            <span>{router.locale === 'en' ? labelEn : label}</span>
          </div>
          <div className="mixology__content-title text-component__title text-reveal">
            {router.locale === 'en' ? titleEn : title}
          </div>
          <div
            className="mixology__content-text text-component__teaser text-reveal"
            dangerouslySetInnerHTML={{ __html: sanitizer(router.locale === 'en' ? textEn : text) }}
          />
          <Button link={routes.Mixology.path} text={router.locale === 'en' ? 'Explore' : 'Esplora'} stroke="dark" />
        </div>
      </div>

      <style jsx>{`
        @import '../../styles/shared';
        .mixology {
          position: relative;
          overflow: hidden;
          @include flex-center;
          background-color: $white;
          width: 100%;
          padding: 20vmax 0;
          @include media('>=large') {
            padding: 0;
            height: 100vh;
            width: auto;
            min-width: 100vw;
          }
        }
        .section-sc {
          @include fill;
          position: absolute;
          pointer-events: none;
        }
        .mixology__content {
          @include flex-center;
          width: 80vw;
          padding: 10vw 5vw;
          background-color: $white;
          border: 1px solid $dark;
          position: relative;
          @include media('>=medium') {
            width: 60vmax;
            padding: 5vmax;
          }
        }
        .mixology__tiles {
          overflow: hidden;
          @include pos100(absolute);
        }
        .mixology__tiles-wrap {
          @include pos100(absolute);
          height: 150%;
          width: 130%;
          left: -15%;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%) rotate(-10deg);
          @include flex-center;
          @include media('>=medium') {
            width: 120%;
            left: -10%;
          }
          @include media('>=large') {
            height: 100%;
            left: -15%;
            top: 5%;
          }
        }
        .mixology__tiles-line {
          height: 100%;
          display: block;
          width: 33%;
          padding: 0 1vmax;
          @include media('>=medium') {
            padding: 0 2vmax;
          }
          @include media('>=large') {
            padding: 0 4vmax;
          }
        }
        .mixology__tiles-line-img {
          @include clean;
          width: 100%;
          height: 50vh;
          margin: 5vmax 0;
          position: relative;
          overflow: hidden;
          @include media('>=large') {
            height: 75vh;
          }

          :global(img) {
            @include pos100(absolute);
            object-fit: cover;
          }
        }
      `}</style>
    </section>
  );
}

export default Mixology;
