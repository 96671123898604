// global
export const resizeDebounceTime = 10; // in ms

// export const wpapiUser = `${process.env.WPAPI_USER}`;
// export const wpapiPass = `${process.env.WPAPI_PASS}`;
// export const wpapiEndpoint = `${process.env.WPAPI_ENDPOINT}`;

// export const wooKey = `${process.env.WOO_KEY}`;
// export const wooSecret = `${process.env.WOO_SECRET}`;
// export const wooEndpoint = `${process.env.WOO_ENDPOINT}`;

export const graphqlEndpoint = 'https://admin.ioleliquore.com/graphql';

export const siteEndpoint = 'https://admin.ioleliquore.com';

// head
export const siteName = 'i.o.l.e';
export const siteSlogan = 'Iole Liquore';
