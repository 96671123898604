const splitWords = function (selector : string, initCache: boolean) {
  const elements = document.querySelectorAll(selector);

  elements.forEach(function (el) {
    // if (!(el as HTMLElement).dataset.splitText || regenerate) {
    if (initCache) {
      (el as HTMLElement).dataset.splitText = el.textContent!;
    }
    el.innerHTML = (el as HTMLElement).dataset.splitText!
      .split(/\s/)
      .map(function (word : string) {
        return word
          .split('-')
          .map(function (word) {
            return '<span class="textLine-word">' + word + '</span>';
          })
          .join('<span class="textLine-hyphen">-</span>');
      })
      .join('<span class="textLine-whitespace"> </span>');
  });
};

export default function splitLines(selector : string, initCache: boolean) {
  const elements = document.querySelectorAll(selector);

  splitWords(selector, initCache);

  elements.forEach((el, groupIdx) => {
    const lines = getLines((el as HTMLElement));

    let wrappedLines = '';
    lines.forEach((wordsArr, lineIdx) => {
      wrappedLines += `<div class="textLine"><span class="textLine-inner" style=transition-delay:0.${groupIdx > 2 ? 2 + lineIdx : groupIdx + lineIdx}s>`;
      wordsArr.forEach(function (word : HTMLSpanElement) {
        wrappedLines += word.outerHTML;
      });
      wrappedLines += '</span></div>';
    });
    el.innerHTML = wrappedLines;
  });
}

let getLines = function (el : HTMLElement) {
  const lines = [];
  let line : any;
  const words = el.querySelectorAll('span');
  let lastTop;
  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    if (word.offsetTop != lastTop) {
      if (!word.classList.contains('whitespace')) {
        lastTop = word.offsetTop;

        line = [];
        lines.push(line);
      }
    }
    line.push(word);
  }
  return lines;
};
