import React from 'react';
import Image from '@/components/Image/WpImage';
import { useRouter } from 'next/router';
import PlantCircle from '@/assets/svgs/plant-circle.svg';
import sanitizer from '@/utils/sanitizer';

type Props = {
  fields: {
    image: { sourceUrl: string; srcSet: string };
    text: string;
    textEn: string;
  };
};

function Ricordo({ fields }: Props) {
  const { image, text, textEn } = fields;
  const router = useRouter();

  return (
    <section className="ricordo" data-scroll-section>
      <div className="section-sc" data-scroll data-scroll-call="ricordo" data-scroll-repeat data-scroll-offset="90%" />
      {/* <div className="section-sc" data-scroll data-scroll-call="bar" data-scroll-repeat /> */}
      <div className="ricordo__content">
        <div
          className="ricordo__content-spinner"
          data-scroll
          data-inview-opacity
          data-scroll-speed="-1.3"
          data-scroll-direction="horizontal"
        >
          <PlantCircle />
        </div>
        <div className="text-component text-component--decoration" data-scroll data-text-reveal>
          <div
            className="ricordo__content-text text-reveal"
            dangerouslySetInnerHTML={{ __html: sanitizer(router.locale === 'en' ? textEn : text) }}
          ></div>
        </div>
      </div>
      <div className="ricordo__img" data-scroll data-image-reveal data-inview-delay="0.2">
        <div className="ricordo__img-wrapper" data-scroll data-scroll-speed="1" data-scroll-direction="horizontal">
          <Image sourceUrl={image?.sourceUrl} sourceSrcSet={image?.srcSet} alt="i.o.l.e." />
        </div>
      </div>

      <style jsx>{`
        @import '../../styles/shared';
        .ricordo {
          position: relative;
          overflow: hidden;
          background-color: $white;
          display: flex;
          flex-direction: column;
          width: 100%;
          @include media('>=large') {
            height: 100vh;
            flex-direction: row;
            width: auto;
            min-width: 100vw;
          }
        }
        .section-sc {
          @include fill;
          position: absolute;
          pointer-events: none;
        }
        .ricordo__content {
          padding: 18vmax 6vmax 15vmax;
          position: relative;
          @include media('>=medium') {
            padding: 18vmax 18vmax 16vmax;
          }
          @include media('>=large') {
            @include flex-center;
            padding: 0 18vw 0 23vw;
          }
        }
        .ricordo__img {
          width: 100%;
          height: 42vh;
          @include media('>=medium') {
            height: 50vh;
          }
          @include media('>=large') {
            width: 50vmax;
            height: 100vh;
          }
        }
        .ricordo__img-wrapper {
          @include pos100(absolute);
          width: 130%;
          left: -15%;
        }
        .text-component {
          width: 100%;
          height: 100%;
          @include flex-center;
          @include media('>=large') {
            height: auto;
            width: 60vw;
          }
        }
        .ricordo__content-text {
          overflow: hidden;
          :global(span) {
            color: $black;
            font-family: var(--font-secondary);
            font-style: italic;
            font-size: var(--text-lg);
            font-weight: 350;
            line-height: 1.8;
            font-size: 1.8rem;
            font-size: clamp(1.45rem, 0.8265rem + 2.7711vw, 2.6rem);
            @include media('>=large') {
              font-size: 2.5rem;
              font-size: clamp(2.2rem, 1.5vw + 1.2rem, 3.4rem);
            }
          }
        }
        .ricordo__content-spinner {
          position: absolute;
          top: 5vmax;
          left: 4vmax;
          width: 18vmax;
          height: 18vmax;
          opacity: 0;
          @include media('>=medium') {
            left: 6vmax;
          }
          @include media('>=large') {
            left: 10vmax;
            width: 20vmax;
            height: 20vmax;
          }

          :global(svg) {
            width: 100%;
            height: auto;
            animation: icon-spin 20s infinite linear;
            transform-origin: center;
          }
        }
      `}</style>
    </section>
  );
}

export default Ricordo;
