import React from 'react';
import Image from '@/components/Image/WpImage';
import { useRouter } from 'next/router';
import sanitizer from '@/utils/sanitizer';
import SentoriTextBg from '@/assets/svgs/sentori-text-bg.svg';
import Button from '@/components/Button/Button';
import routes from '@/data/routes';

type Props = {
  fields: {
    image1: { sourceUrl: string; srcSet: string };
    image2: { sourceUrl: string; srcSet: string };
    image3: { sourceUrl: string; srcSet: string };
    label: string;
    labelEn: string;
    title: string;
    titleEn: string;
    text: string;
    textEn: string;
  };
};

function Sentori({ fields }: Props) {
  const { image1, image2, image3, label, labelEn, title, titleEn, text, textEn } = fields;
  const router = useRouter();

  return (
    <section className="sentori" data-scroll-section>
      <div className="sentori-sc" data-scroll data-scroll-repeat />
      <div
        className="sentori__bgText"
        data-scroll
        data-inview-opacity
        data-scroll-speed="-1.3"
        data-scroll-direction="horizontal"
      >
        <SentoriTextBg />
      </div>
      <div className="sentori__content">
        <div className="text-component" data-scroll data-text-reveal>
          <div className="text-component__label">
            <span>{router.locale === 'en' ? labelEn : label}</span>
          </div>
          <div className="sentori__content-title text-component__title text-reveal">
            {router.locale === 'en' ? titleEn : title}
          </div>
          <div
            className="sentori__content-text text-component__teaser text-reveal"
            dangerouslySetInnerHTML={{ __html: sanitizer(router.locale === 'en' ? textEn : text) }}
          />
          <Button link={routes.Tonico.path} text={router.locale === 'en' ? 'Explore' : 'Esplora'} stroke="dark" />
        </div>

        <div className="sentori__content-tiles">
          <div className="sentori__content-tiles-imgRight" data-scroll data-image-zoom-reveal>
            <figure>
              <Image sourceUrl={image2?.sourceUrl} sourceSrcSet={image2?.srcSet} alt="i.o.l.e." />
            </figure>
          </div>
          <div
            className="sentori__content-tiles-imgLeft"
            data-scroll
            data-scroll-speed="0.5"
            data-scroll-direction="vertical"
            data-image-zoom-reveal
          >
            <figure>
              <Image sourceUrl={image1?.sourceUrl} sourceSrcSet={image1?.srcSet} alt="i.o.l.e." />
            </figure>
          </div>
        </div>
      </div>
      <div className="sentori__image" data-scroll data-image-reveal data-inview-delay="0.2">
        <div className="sentori__image-wrapper" data-scroll data-scroll-speed="1" data-scroll-direction="horizontal">
          <Image sourceUrl={image3?.sourceUrl} sourceSrcSet={image3?.srcSet} alt="i.o.l.e." />
        </div>
      </div>

      <style jsx>{`
        @import '../../styles/shared';
        .sentori {
          position: relative;
          overflow: hidden;
          @include flex-center;
          background-color: $white;
          width: 100%;
          flex-direction: column;
          @include media('>=large') {
            flex-direction: row;
            height: 100vh;
            width: auto;
            min-width: 100vw;
          }
        }
        .section-sc {
          @include fill;
          position: absolute;
          pointer-events: none;
        }
        .sentori__bgText {
          position: absolute;
          top: px(-10);
          left: 6vw;
          width: 150vw;
          @include media('>=medium') {
            width: 130vw;
          }
          @include media('>=large') {
            top: 1vmax;
            left: 7vmax;
            width: 80vmax;
          }

          :global(svg) {
            width: 100%;
            height: auto;
            opacity: 0.04;
            :global(path) {
              fill: $dark;
            }
          }
        }
        .sentori__content {
          height: 100%;
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 14vmax 0 7vmax;
          @include media('>=medium') {
            width: 85%;
          }
          @include media('>=large') {
            width: auto;
            flex-direction: row;
            padding: 6vmax 12vmax 6vmax 15vmax;
          }
        }
        .text-component {
          width: 70vw;
          padding-bottom: var(--space-lg);
          @include media('>=medium') {
            width: 60vw;
          }
          @include media('>=large') {
            padding-bottom: 0;
            width: 40vmax;
          }
        }
        .sentori__content-tiles {
          width: 100%;
          height: 100%;
          display: grid;
          position: relative;
          grid-template-columns: repeat(12, 1fr);
          margin-bottom: var(--space-xxl);
          @include media('>=medium') {
            margin-bottom: 20vmax;
          }
          @include media('>=large') {
            margin-bottom: 0;
            padding-left: 10vmax;
            width: 80vmax;
          }
        }
        .sentori__content-tiles-imgLeft {
          grid-column: 1 / 6;
          grid-row: 1;
          width: 100%;
          top: 20%;
          height: 60vw;
          @include media('>=large') {
            height: 80%;
          }
        }
        .sentori__content-tiles-imgRight {
          grid-column: 5 /-1;
          grid-row: 1;
          width: 100%;
          top: 0;
          height: 60vw;
          @include media('>=large') {
            height: 60%;
          }
        }
        .sentori__image {
          width: 100%;
          height: 42vh;
          @include media('>=medium') {
            height: 50vh;
          }
          @include media('>=large') {
            width: 50vmax;
            height: 100vh;
          }
        }
        .sentori__image-wrapper {
          @include pos100(absolute);
          width: 130%;
          left: -15%;
        }
      `}</style>
    </section>
  );
}

export default Sentori;
